<template>
  <div>
    <validation-observer ref="simpleRules">
      <!-- User Info: Input Fields -->
      <b-form>
        <b-row>
          <!-- Field: Username -->
          <b-col cols="12" md="4">
            <b-form-group>
              <label>First Name</label>
              <validation-provider
                #default="{ errors }"
                rules="required"
                name="First Name"
              >
                <b-form-input v-model="model.first_name" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <!-- Field: LastName -->
          <b-col cols="12" md="4">
            <b-form-group>
              <label>Last Name</label>
              <validation-provider
                #default="{ errors }"
                name="Last Name"
                rules="required"
              >
                <b-form-input
                  v-model="model.last_name"
                  :state="errors.length > 0 ? false : null"
                  placeholder=""
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <!-- Field: Mail -->
          <b-col cols="12" md="4">
            <b-form-group>
              <label>Email</label>
              <validation-provider
                #default="{ errors }"
                name="Email"
                rules="required|email"
              >
                <b-form-input
                  v-model="model.mail"
                  :state="errors.length > 0 ? false : null"
                  placeholder=""
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <!-- Field: PhoneNumbre -->
          <b-col cols="12" md="4">
            <b-form-group label="Phone Numbre" label-for="nameCountry">
              <b-form-input id="phoneNumbre" v-model="model.phone_number" />
            </b-form-group>
          </b-col>

          <!-- Field: Adress -->
          <b-col cols="12" md="4">
            <b-form-group label="Adress" label-for="nameCountry">
              <b-form-input id="Adress" v-model="model.adress" />
            </b-form-group>
          </b-col>

          <!-- Field: BirthDate -->
          <b-col cols="12" md="4">
            <b-form-group>
              <label>Birth Date</label>
              <validation-provider
                #default="{ errors }"
                name="Birth Date"
                rules="required"
              >
                <flat-pickr
                  v
                  class="form-control"
                  v-model="model.birth_date"
                  :state="errors.length > 0 ? false : null"
                  placeholder=""
                />
                <flat-pickr
                  v
                  class="form-control"
                  v-model="model.birth_date"
                  :state="errors.length > 0 ? false : null"
                  placeholder=""
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <!-- Field: City -->
          <b-col cols="12" md="4">
            <b-form-group label="City" label-for="user-status">
              <v-select
                v-model="model.cityId"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="cityOptions"
                :reduce="(val) => val.value"
                :clearable="false"
                input-id="user-status"
              />
            </b-form-group>
          </b-col>

          <!-- Field: Status -->
          <b-col cols="12" md="4">
            <b-form-group label="Status" label-for="user-status">
              <v-select
                v-model="userData.status"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="statusOptions"
                :reduce="(val) => val.value"
                :clearable="false"
                input-id="user-status"
                type="booleano"
              />
            </b-form-group>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>

    <!-- Action Buttons -->
    <b-button
      variant="primary"
      class="mb-1 mb-sm-0 mr-0 mr-sm-1"
      :block="$store.getters['app/currentBreakPoint'] === 'xs'"
      @click.prevent="validationForm(userData.id)"
    >
      Save Changes Customer
    </b-button>
    <b-button
      variant="outline-secondary"
      type="reset"
      :block="$store.getters['app/currentBreakPoint'] === 'xs'"
    >
      Reset
    </b-button>
  </div>
</template>

<script>
import {
  BButton,
  BMedia,
  BAvatar,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BForm,
  BTable,
  BCard,
  BCardHeader,
  BCardTitle,
  BFormCheckbox,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import {
  required,
  email,
  confirmed,
  url,
  between,
  alpha,
  integer,
  password,
  min,
  digits,
  alphaDash,
  length,
} from "@validations";
import { avatarText } from "@core/utils/filter";
import vSelect from "vue-select";
import router from "@/router";
import store from "@/store";
import { ref, onUnmounted } from "@vue/composition-api";
import flatPickr from "vue-flatpickr-component";
import useUsersList from "../country-list/useUsersList";
import userStoreModule from "../userStoreModule";

// Notification
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  components: {
    BButton,
    BMedia,
    BAvatar,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BTable,
    BCard,
    BCardHeader,
    BCardTitle,
    BFormCheckbox,
    vSelect,
    flatPickr,
    ValidationProvider,
    ValidationObserver,
  },
  props: {
    userData: {
      type: Object,
      required: true,
    },
  },
  methods: {
    validationForm(id) {
      this.$refs.simpleRules.validate().then((success) => {
        if (success) {
          // eslint-disable-next-line
          this.updateCountry(id);
        }
      });
    },
  },
  setup(props) {
    const USER_APP_STORE_MODULE_NAME = "app-user";

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME))
      store.registerModule(USER_APP_STORE_MODULE_NAME, userStoreModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME))
        store.unregisterModule(USER_APP_STORE_MODULE_NAME);
    });

    const toast = useToast();

    const { resolveUserRoleVariant } = useUsersList();

    const cityOptions = ref([]);

    const statusOptions = [
      { label: "Active", value: true },
      { label: "Inactive", value: false },
    ];
    const userData1 = ref(null);

    const {
      id,
      first_name,
      last_name,
      mail,
      phone_number,
      birth_date,
      cityId,
      adress,
      status,
    } = props.userData;
    const model = ref({
      id: id,
      first_name: first_name,
      last_name: last_name,
      mail: mail,
      phone_number: phone_number,
      birth_date: birth_date,
      cityId: cityId,
      adress: adress,
      id_user: 1,
      photo: "photo",
      status: status,
    });

    const city = [];
    store
      .dispatch("app-user/fetchHelpers")
      .then((response) => {
        const { cities } = response.data.data;

        cities.map((data, item) => {
          city.push({ label: data.name, value: data.id, id: item });
        });

        cityOptions.value = city;
      })
      .catch((error) => {
        if (error.response.status === 404) {
          userData1.value = undefined;
        }
      });

    const updateCountry = (id) => {
      store
        .dispatch("app-user/updateCountry", {
          model,
        })
        .then((response) => {
          userData1.value = response.data;
          toast({
            component: ToastificationContent,
            props: {
              title: `The office  was created correctly`,
              icon: "AlertTriangleIcon",
              variant: "success",
            },
          });
          router.push({ name: "apps-customers-list" });
        })
        .catch((error) => {
          if (error.response.status === 404) {
            userData1.value = undefined;
          }
        });
      console.log(userData1);
    };

    return {
      resolveUserRoleVariant,
      avatarText,
      statusOptions,
      cityOptions,
      model,

      updateCountry,

      alpha,
    };
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
